import { 
    useContext,
    useState } from 'react';

import styles from './candidateInfo.module.scss';
import checkIcon from  '../../../assets/icons/check.svg';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';

import PersonalInfo from './components/personal_info/personal_info';
import WorkExperience from './components/work_experience/work_experience';
import AcademicInfo from './components/academic_info/academic_info';
import Certifications from './components/certifications/certifications';
import Languages, { validateLanguageRequest } from './components/languages/languages';
import Skills, { validateSkillRequest } from './components/skills/skills';
import { CandidateContext } from './context';
import { editPreviouJob, newPreviouJob } from '../../api/previousJobClient';
import { editEducation, newEducation } from '../../api/educationClient';
import { editCourse, newCourse } from '../../api/courseClient';
import { saveAll } from '../../api/skillClient';
import { deleteLanguage, editLanguage, newLanguage } from '../../api/languageClient';

import { 
    validateIsAlpha,
    validateNotNull,
    validatePhoneIsNumber,
    validateMinPhoneLength,
    validate1500Length} from '../../core/validators';
import { LanguagesLevel } from './components/languages/level_description';
import Software from './components/skills/software';
import { saveTalent, testConnection, updateTalent } from '../../api/talentClient';

const CandidateForms = ({
    stepIndex,
    setStepIndex,
    displayNotification,
    resumeViewFlag,
    personalInfo,
    setPersonalInfo,
    appContext
}) => {

    const [personalInfoErrors, setPersonalInfoErrors] = useState(false)
    const [descriptionFocus, setDescriptionFocus] = useState(false);
    const [photoError, setPhotoError] = useState(false);
    const [file, setFile] = useState(undefined);

    const personalInfoValidationFields = {
        firstName: [validateIsAlpha, validateNotNull],
        lastName: [validateIsAlpha, validateNotNull],
        position: [validateNotNull],
        description: [validateNotNull, validate1500Length],
        phone: [validatePhoneIsNumber, validateMinPhoneLength, validateNotNull],
        state: [validateNotNull],
    }

    const { 
        personalInfoCache,
        workExperiences,
        academics,
        certifications,
        languages,
        languagesCache,
        setLanguageErrors,
        skills,
        setSkills,
        setSkillErrors,
        skillsCache,
        setSkillsCache,
    } = useContext( CandidateContext );

    const renderBackButton = (title, stepValue) => {
        return (stepIndex === 7 || (!resumeViewFlag && stepIndex >= 1 && stepIndex <= 6)) && (
        <div className={ styles.backbutton } onClick = { () => setStepIndex( stepValue ? stepValue : stepIndex - 1) }>
            <img src={ backArrowIcon } alt="" /><span>{ title ? title : 'Volver' }</span>
        </div>
    )}

    const formSections = [
        <PersonalInfo
            personalInfo={ personalInfo }
            setPersonalInfo={ setPersonalInfo }
            personalInfoErrors={ personalInfoErrors }
            file={ file }
            setFile={ setFile }
            descriptionFocus={ descriptionFocus }
            photoError={ photoError }
            userData={ appContext.userData }
        />,
        <WorkExperience resumeViewFlag={ resumeViewFlag } renderBackButton={ renderBackButton } />,
        <AcademicInfo resumeViewFlag={ resumeViewFlag } renderBackButton={ renderBackButton } />,
        <Certifications resumeViewFlag={ resumeViewFlag } renderBackButton={ renderBackButton } />,
        <Languages resumeViewFlag={ resumeViewFlag } renderBackButton={ renderBackButton } setStepIndex={ setStepIndex } displayNotification={ displayNotification } />,
        <Skills resumeViewFlag={ resumeViewFlag } renderBackButton={ renderBackButton } />,
        <Software resumeViewFlag={ resumeViewFlag } renderBackButton={ renderBackButton } />,
        <LanguagesLevel renderBackButton={ renderBackButton } />
    ]
    const optionalButtons = [
        null,
        appContext.isDesktopOrLaptop ? 'No cuento con experiencia' : 'Omitir',
        null,
        appContext.isDesktopOrLaptop ? 'No cuento con cursos o certificaciones' : 'Omitir',
        'Omitir',
        null,
        'Omitir'
    ]

    const profileImageValidFormat = [
        "image/jpeg",
        "image/JPEG",
        "image/png",
        "image/PNG",
        "image/jpg",
        "image/JPG"
    ]

    const msgNotification = [
        {
            msgSave: null,
            msgCancel: null
        },
        {
            msgSave: 'Información guardada',
            msgCancel: 'No has agregado experiencia profesional para guardar en tu perfil'
        },
        {
            msgSave: 'Información guardada',
            msgCancel: 'No has agregado formación académica para guardar en tu perfil'
        },
        {
            msgSave: 'Información guardada',
            msgCancel: 'No has agregado cursos o certificaciones para guardar en tu perfil'
        },
        {
            msgSave: 'Información guardada',
            msgCancel: 'No has agregado idiomas para guardar en tu perfil'
        },
        {
            msgSave: 'Información guardada',
            msgCancel: 'No has agregado habilidades para guardar en tu perfil'
        },
        {
            msgSave: 'Información guardada',
            msgCancel: 'No has agregado software para guardar en tu perfil'
        },
    ]

    const validatePersonalInfo = () => {
        let firstFocus = true;
        setDescriptionFocus(false);
        setPhotoError(false);
        const errorObject = {};
        var helper;
        // validating fields
        for(const field in personalInfoValidationFields) {
            for (const validator in personalInfoValidationFields[field]) {
                helper = personalInfoValidationFields[field][validator](personalInfo[field])
                if (helper) {
                    errorObject[field] = helper
                    if( firstFocus ) {
                        if ( field === 'description' ) {
                            setDescriptionFocus(true);
                        } else {
                            document.getElementsByName(field)[0].focus();
                        }
                        firstFocus = false;
                    }
                }
            }
        }
        // validating selected country
        if (!personalInfo.country) {
            errorObject["country"] = ["Este campo es obligatorio"]
        }

        // validando la imagen
        if (!file && !personalInfo.imgProfile) {
            errorObject["imgProfile"] = ["*Por favor, agrega una foto para tu perfil"]
            setPhotoError(true);
        }

        if(file) {
            // validando el formato
            if (file.size > 10485760) {
                errorObject["imgProfile"] = ["La imagen que intentas agregar excede el peso máximo permitido (10MB)"]
                setPhotoError(true);
            }
            if (!profileImageValidFormat.includes(file.type)) {
                errorObject["imgProfile"] = ["La imagen que intentas agregar no está en ninguno de los formatos permitidos (JPG, JPEG, PNG)"]
                setPhotoError(true);
            }
        }
        return errorObject
    }

    const handlePersonalInfoSubmit = () => {
        const validationErrors = validatePersonalInfo();
        if (Object.keys(validationErrors).length === 0) {
            appContext.showLoading(true, 'Guardando', styles.no_scroll);
            const description = personalInfo.description.replace(/[^\w\n\sÀ-ÿ\u00f1\u00d1.,;:]/g, '');
            const requestBody = {
                firstName: personalInfo.firstName,
                lastName: personalInfo.lastName,
                position: personalInfo.position,
                description,
                email: personalInfo.email,
                phone: personalInfo.phone,
                state: personalInfo.state,
                country: personalInfo.country
            }

            if (file) {
                requestBody["imgProfile"] = file
            }
            if (!appContext.userData.userProfile.talentId) {
                requestBody["userId"] = appContext.userData.userProfile.id
                saveTalent(requestBody, appContext.userData.token).then( response => {
                    if( response.status === 200 && response.data && response.data.code === 201 && response.data.result ) {
                        const userData = {
                            ...appContext.userData,
                            userProfile: {
                                ...appContext.userData.userProfile, 
                                talentId: response.data.result.id
                            }
                        };
                        setPersonalInfo({ ...personalInfo, imgProfile: response.data.result.imgProfile})
                        localStorage.setItem('userInfo', JSON.stringify(userData));
                        appContext.setUserData(userData);
                        appContext.showLoading(false, '', styles.no_scroll);
                        displayNotification("Información guardada", false, 5000);
                        if (!resumeViewFlag) {
                            window.scrollTo(0,0);
                            setStepIndex(1);
                        } else {
                            window.scrollTo(0,0);
                            setStepIndex(8);
                        }
                    } else {
                        appContext.showLoading(false, '', styles.no_scroll);
                        displayNotification("Ha ocurrido un error al guardar la información", true, 5000);
                        appContext.setUserData(JSON.parse(localStorage.getItem('userInfo')));
                    }
                }).catch( error => {
                    console.log(error);
                    displayNotification(`Ha ocurrido un error al guardar la información [${error.message}]`, true, 5000);
                    appContext.showLoading(false, '', styles.no_scroll);
                })
            } else {
                requestBody["id"] = appContext.userData.userProfile.talentId
                updateTalent(requestBody, appContext.userData.token).then( response => {
                    appContext.showLoading(false, '', styles.no_scroll);
                    if( response.status === 200 && response.data && response.data.code === 202 && response.data.result && response.data.result.imgProfile ) {
                        setPersonalInfo({ ...personalInfo, imgProfile: response.data.result.imgProfile})
                    }
                    displayNotification("Información guardada", false, 5000);
                    if (!resumeViewFlag) {
                        window.scrollTo(0,0);
                        setStepIndex(1);
                    } else {
                        window.scrollTo(0,0);
                        setStepIndex(8);
                    }
                }).catch( error => {
                    console.log(error);
                    appContext.showLoading(false, '', styles.no_scroll);
                })
            }
        } else {
            setPersonalInfoErrors(validationErrors);
        }
    }

    const deleteLanguages = () => {
        const languagesToDelete = languagesCache.filter( l => !languages.some( l2 => l2.languageId === l.languageId) );
        languagesToDelete.forEach( la => {
            deleteLanguage(appContext.userData.userProfile.talentId, la.languageId, appContext.userData.userProfile.id, appContext.userData.token).then((response) => {
                if( response.data && response.data.code === 201 && response.data.result ) {
                    console.log('Language deleted')
                }
            }).catch(error => {
                console.log(error);
            })
        })
    }

    const saveSkills = (skills, categories) => {
        appContext.showLoading(true, 'Guardando', styles.no_scroll);
        const skillsWithUser = [];
        skills.forEach( s => skillsWithUser.push({ ...s, 'userId': appContext.userData.userProfile.id }))
        const request = {
            'saveSkillTagRequestList': skillsWithUser,
            'categories': categories
        }
        saveAll(appContext.userData.userProfile.talentId, request, appContext.userData.userProfile.id, appContext.userData.token).then((response) => {
            if( response.data && response.data.code === 202 ) {
                appContext.showLoading(false, '', styles.no_scroll);
                displayNotification(msgNotification[stepIndex].msgSave, false, 3000);
                setSkills([]);
                setSkillsCache([]);
                gotoNext();
            } else {
                console.log('Error en el servicio', response);
                appContext.showLoading(false, '', styles.no_scroll);
            }
        }).catch(error => {
            console.log('Error al ejecutar el servicio', error);
            appContext.showLoading(false, '', styles.no_scroll);
        })
    }

    const executeMultiPromises = (requestArray, personalInfo) => {
        appContext.showLoading(true, 'Guardando', styles.no_scroll);
        Promise.all(requestArray).then( response => {
            appContext.showLoading(false, '', styles.no_scroll);
            setPersonalInfo(personalInfo);
            gotoNext();
        }).catch( error => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log(error);
        });
    }

    const nextStep = isSave => {
        if( !isSave ) {
            gotoNext();
        } else {
            displayNotification(msgNotification[stepIndex].msgCancel, true, 3000);
        }
    }

    const gotoNext = () => {
        displayNotification(null, true, 1);
        if (!resumeViewFlag && stepIndex < 6) {
            window.scrollTo(0,0);
            setStepIndex(stepIndex + 1)
        } else {
            window.scrollTo(0,0);
            setStepIndex(8);
        }
    }

    const onCancel = () => {
        if( stepIndex === 0 ) {
            setPersonalInfo( { ...personalInfoCache } )
        } else if ( stepIndex === 5 || stepIndex === 6 ) {
            setSkillsCache(skills);
        }
        gotoNext();
    }

    const handleOnClickSave = (isSave) => {
        testConnection(appContext.userData.token).then((response) => {
            const requestArray = [];
            if ( stepIndex === 0 ) {
                handlePersonalInfoSubmit()
                return
            } else if ( stepIndex === 1 ) {
                if( workExperiences.length === 0 ) {
                    nextStep(isSave);
                } else if (isSave) {
                    workExperiences.forEach(we => {
                        if (we.id === 0) {
                            requestArray.push(newPreviouJob(appContext.userData.userProfile.talentId, we, appContext.userData.userProfile.id, appContext.userData.token));
                        } else {
                            requestArray.push(editPreviouJob(appContext.userData.userProfile.talentId, we, appContext.userData.userProfile.id, appContext.userData.token));
                        }
                    });
                    let pi = { ...personalInfo };
                    pi.previousJobs = workExperiences;
                    executeMultiPromises(requestArray, pi);
                }
            } else if ( stepIndex === 2 ) {
                if( academics.length === 0 ) {
                    displayNotification(msgNotification[stepIndex].msgCancel, true, 3000);
                } else if (isSave) {
                    academics.forEach(ac => {
                        if (ac.courseId === 0) {
                            requestArray.push(newEducation(appContext.userData.userProfile.talentId, ac, appContext.userData.userProfile.id, appContext.userData.token));
                        } else {
                            requestArray.push(editEducation(appContext.userData.userProfile.talentId, ac, appContext.userData.userProfile.id, appContext.userData.token));
                        }
                    });
                    let pi = { ...personalInfo };
                    pi.educations = academics;
                    executeMultiPromises(requestArray, pi);
                }
            } else if ( stepIndex === 3 ) {
                if( certifications.length === 0 ) {
                    nextStep(isSave);
                } else if (isSave) {
                    certifications.forEach(ce => {
                        if(ce.courseId === 0) {
                            requestArray.push(newCourse(appContext.userData.userProfile.talentId, ce, appContext.userData.userProfile.id, appContext.userData.token));
                        } else {
                            requestArray.push(editCourse(appContext.userData.userProfile.talentId, ce, appContext.userData.userProfile.id, appContext.userData.token));
                        }
                    });
                    let pi = { ...personalInfo };
                    pi.courses = certifications;
                    executeMultiPromises(requestArray, pi);
                }
            } else if ( stepIndex === 4 ) {
                if( languages.length === 0 ) {
                    nextStep(isSave);
                } else if (isSave) {
                    const errorValidations = validateLanguageRequest(languages);
                    const errors = Object.keys(errorValidations);
                    const errorLang = errors.filter( w => w.includes('language_')).length > 0;
                    const errorLevel = errors.filter( w => w.includes('langLevel_')).length > 0;
                    if ( !(errorLang || errorLevel) ) {
                        languages.forEach(la => {
                            if( la.id ) {
                                requestArray.push(editLanguage(appContext.userData.userProfile.talentId, la, appContext.userData.userProfile.id, appContext.userData.token));
                            } else {
                                requestArray.push(newLanguage(appContext.userData.userProfile.talentId, la, appContext.userData.userProfile.id, appContext.userData.token));
                            }
                        });
                        deleteLanguages();
                        let pi = { ...personalInfo };
                        pi.languages = languages;
                        executeMultiPromises(requestArray, pi);
                    } else {
                        setLanguageErrors(errorValidations);
                    }
                }
            } else if ( stepIndex === 5 || stepIndex === 6 ) {
                if( skillsCache.length === 0 ) {
                    nextStep(isSave);
                } else if (isSave) {
                    const totalHardSkills = skillsCache.filter( s => s.categoryId === 1001100001).length;
                    const totalPersonalSkills = skillsCache.filter( s => s.categoryId === 1001100002).length;
                    if( stepIndex === 5 && ( totalHardSkills < 5 || totalPersonalSkills < 5 ) ) {
                        displayNotification('Agrega al menos 5 competencias técnicas y 5 competencias personales', true, 0);
                    } else {
                        const categories = stepIndex === 5 ? [1001100001, 1001100002] : ( stepIndex === 6 ? [1001100004] : [] );
                        const skillsToSave = stepIndex === 5 ? skillsCache.filter( s => s.categoryId === 1001100001 || s.categoryId === 1001100002 ) : 
                            ( stepIndex === 6 ? skillsCache.filter( s => s.categoryId === 1001100004 ) : [] );
    
                        // validando antes de guardar
                        // revisando si es software o skills
                        let validationDictHelper = {}
                        if (stepIndex === 6) {
                            validationDictHelper = validateSkillRequest(1001100004, skillsToSave)
                        } else {
                            validationDictHelper = Object.assign(
                                validateSkillRequest(1001100001, skillsToSave),
                                validateSkillRequest(1001100002, skillsToSave));
                        }
                        console.log(validationDictHelper)
                        if (Object.keys(validationDictHelper).length === 0) {
                            // console.log('Snapshot', skills);
                            // console.log('Cache', skillsCache);
                            saveSkills(skillsToSave, categories);
                        }
                        setSkillErrors(validationDictHelper);
                    }
                }
            } else {
                gotoNext();
            }            
        }).catch( error => {
            if( error.code === 'ERR_NETWORK' ) {
                displayNotification(null, false, false, true);
            }
        })
    }

    const showCancelButton = (
        (stepIndex === 1 && workExperiences.length === 0 && !resumeViewFlag)
        || (stepIndex === 2 && academics.length === 0 && !resumeViewFlag)
        || (stepIndex === 3 && certifications.length === 0 && !resumeViewFlag)
        || (stepIndex === 4 && languages.length === 0 && !resumeViewFlag)
        || (stepIndex === 5 && skills.length === 0 && !resumeViewFlag)
    )

    const renderSteper = () => appContext.isDesktopOrLaptop && (
        <div className={ styles.roadmap }>
            <div className={ styles.roadmap_step }>
                
                <div className={ styles.bullet_icon }>
                    {
                        stepIndex >= 0 ? <p className={ styles.active }>{ '\u29bf' }</p> : <p className={ styles.idle }>{ '\u20DD' }</p>
                    }
                </div>
                <p className={ stepIndex === 0 ? styles.active : '' }>Informaci&oacute;n Personal</p>
                <div className={ styles.icon_container + " " + (stepIndex >= 1 ? styles.visible : null) }>
                    <img src={ checkIcon } alt="" />
                </div>
            </div>
            <div className={ styles.roadmap_step }>
                <div className={ styles.separator + " " + (stepIndex >= 1 ? styles.active : null) }></div>
                <div className={ styles.bullet_icon }>
                    {
                        stepIndex >= 1 ? <p className={ styles.active }>{ '\u29bf' }</p> : <p className={ styles.idle }>{ '\u20DD' }</p>
                    }
                </div>
                <p className={stepIndex === 1 ? styles.active : '' }>Experiencia Profesional</p>
                <div className={ styles.icon_container + " " + (stepIndex >= 2 ? styles.visible : null) }>
                    <img src={ checkIcon } alt="" />
                </div>
            </div>
            <div className={ styles.roadmap_step }>
                <div className={ styles.separator + " " + (stepIndex >= 2 ? styles.active : null) }></div>
                <div className={ styles.bullet_icon }>
                    {
                        stepIndex >= 2 ? <p className={ styles.active }>{ '\u29bf' }</p> : <p className={ styles.idle }>{ '\u20DD' }</p>
                    }
                </div>
                <p className={stepIndex === 2 ? styles.active : '' }>Formaci&oacute;n Acad&eacute;mica</p>
                <div className={ styles.icon_container + " " + (stepIndex >= 3 ? styles.visible : null) }>
                    <img src={ checkIcon } alt="" />
                </div>
            </div>
            <div className={ styles.roadmap_step }>
                <div className={ styles.separator + " " + (stepIndex >= 3 ? styles.active : null) }></div>
                <div className={ styles.bullet_icon }>
                    {
                        stepIndex >= 3 ? <p className={ styles.active }>{ '\u29bf' }</p> : <p className={ styles.idle }>{ '\u20DD' }</p>
                    }
                </div>
                <p className={stepIndex === 3 ? styles.active : '' }>Certificaciones y Cursos</p>
                <div className={ styles.icon_container + " " + (stepIndex >= 4 ? styles.visible : null) }>
                    <img src={ checkIcon } alt="" />
                </div>
            </div>
            <div className={ styles.roadmap_step }>
                <div className={ styles.separator + " " + (stepIndex >= 4 ? styles.active : null) }></div>
                <div className={ styles.bullet_icon }>
                    {
                        stepIndex >= 4 ? <p className={ styles.active }>{ '\u29bf' }</p> : <p className={ styles.idle }>{ '\u20DD' }</p>
                    }
                </div>
                <p className={stepIndex === 4 || stepIndex === 7 ? styles.active : '' }>Idiomas</p>
                <div className={ styles.icon_container + " " + (stepIndex >= 5 ? styles.visible : null) }>
                    <img src={ checkIcon } alt="" />
                </div>
            </div>
            <div className={ styles.roadmap_step }>
                <div className={ styles.separator + " " + (stepIndex >= 5 ? styles.active : null)}></div>
                <div className={ styles.bullet_icon }>
                    {
                        stepIndex >= 5 ? <p className={ styles.active }>{ '\u29bf' }</p> : <p className={ styles.idle }>{ '\u20DD' }</p>
                    }
                </div>
                <p className={stepIndex === 5 ? styles.active : '' }>Habilidades</p>
                <div className={ styles.icon_container + " " + (stepIndex >= 6 ? styles.visible : null) }>
                    <img src={ checkIcon } alt="" />
                </div>
            </div>
            <div className={ styles.roadmap_step }>
                <div className={ styles.separator + " " + (stepIndex >= 6 ? styles.active : null)}></div>
                <div className={ styles.bullet_icon }>
                    {
                        stepIndex >= 6 ? <p className={ styles.active }>{ '\u29bf' }</p> : <p className={ styles.idle }>{ '\u20DD' }</p>
                    }
                </div>
                <p className={stepIndex === 6 ? styles.active : '' }>Software</p>
                <div className={ styles.icon_container + " " + (stepIndex >= 7 ? styles.visible : null) }>
                    <img src={ checkIcon } alt="" />
                </div>
            </div>
        </div>
    )

    const renderSteperMobile = () => !appContext.isDesktopOrLaptop && (
        <>
            <div className={ styles.roadmap }>
                <div className={ styles.roadmap_title }>
                    { stepIndex === 0 && <p>Informaci&oacute;n Personal</p> }
                    { stepIndex === 1 && <p>Experiencia Profesional</p> }
                    { stepIndex === 2 && <p>Formaci&oacute;n Acad&eacute;mica</p> }
                    { stepIndex === 3 && <p>Certificaciones y Cursos</p> }
                    { stepIndex === 4 && <p>Idiomas</p> }
                    { stepIndex === 5 && <p>Habilidades</p> }
                    { stepIndex === 6 && <p>Software</p> }
                </div>
            </div>
            <div className={ styles.roadmap_step }>
                {stepIndex === 0 && <div className={ styles.progress } style={{ width: '12%' }}></div>}
                {stepIndex === 1 && <div className={ styles.progress } style={{ width: '24%' }}></div>}
                {stepIndex === 2 && <div className={ styles.progress } style={{ width: '36%' }}></div>}
                {stepIndex === 3 && <div className={ styles.progress } style={{ width: '48%' }}></div>}
                { (stepIndex === 4 || stepIndex === 7) && <div className={ styles.progress } style={{ width: '60%' }}></div>}
                {stepIndex === 5 && <div className={ styles.progress } style={{ width: '72%' }}></div>}
                {stepIndex === 6 && <div className={ styles.progress } style={{ width: '84%' }}></div>}
            </div>
        </>
    )

    const styleButtons = appContext.isDesktopOrLaptop ? undefined : { width:  optionalButtons[stepIndex] && showCancelButton ? '50%' : '100%' }

    return (
        <>
            { stepIndex !== 6 && renderSteperMobile() }
            <div className={ styles.candidate_columns }>
                <div className={ styles.candidate_form }>
                    { appContext.isDesktopOrLaptop && renderBackButton( null, stepIndex === 7 ? 4 : null) }
                    { formSections[stepIndex] }
                    { appContext.isDesktopOrLaptop && stepIndex !== 7 && <hr /> }

                    <div className={ styles.buttons }>
                        {
                            optionalButtons[stepIndex] && showCancelButton && (
                                <button className={ styles.cancel_button } style={ styleButtons } onClick={() => handleOnClickSave(false)}>{ optionalButtons[stepIndex] }</button>
                            )
                        }
                        {
                            stepIndex <= 6 && resumeViewFlag && (
                                <button className={ styles.cancel_button } style={ styleButtons } onClick={() => onCancel()}>Cancelar</button>
                            )
                        }
                        { stepIndex !== 7 && (<button style={ styleButtons } onClick={() => handleOnClickSave(true)}>{ !resumeViewFlag ? `Guardar${ appContext.isDesktopOrLaptop ? ' y Continuar' : ''}` : `Guardar${ appContext.isDesktopOrLaptop ? ' Cambios' : ''}` }</button>) }
                    </div>
                </div>
                { stepIndex !== 7 && renderSteper() }
            </div>
        </>
    )
}

export default CandidateForms;